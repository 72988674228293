import React from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';

import { useTranslation } from 'src/i18n';
import { getProductionStubTags } from 'utils/productions';

import classes from './ProductionTags.module.scss';

const ProductionTags = ({ isExpanded = false, production, hasWorkDetails }) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const { mainTags } = getProductionStubTags(production);
  const firstTag = mainTags?.[0];

  return (
    <div
      className={classnames(classes.parent, {
        [classes.worksParent]: !!hasWorkDetails && !isExpanded,
        [classes.isExpanded]: !!isExpanded,
      })}
    >
      {!isExpanded && firstTag?.label && (
        <Typography size="12" className={classes.tag}>
          {t(firstTag?.label)}
        </Typography>
      )}
      {isExpanded &&
        mainTags?.map((tag, i) => (
          <Typography key={i} size="12" className={classes.tag}>
            {t(tag?.label)}
          </Typography>
        ))}
    </div>
  );
};

export default ProductionTags;
